import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// style - 自定义的全局样式
import '@assets/style/index.scss'
// element - element按需引入的组件和修改后的主题
import './element'
// components - 第三方或自定义的组件
import '@/components'
// echarts - 可视化图表库
import * as echarts from 'echarts'
// router - 路由前后守卫和登录认证
import '@/permission'
// settings - 初始化自定义配置
import { settings } from '@/settings'
// util - 加载工具包
import '@/utils'
// moment - 日期处理类库
import moment from 'moment'

window._AMapSecurityConfig = {
  securityJsCode: '2f02220adf564cf99a747fc9f3904e73'
}
moment.locale('zh-cn') // 切换中文环境
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$settings = settings

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
